import autoComplete from "@tarekraafat/autocomplete.js";

export default class SearchBar {

    constructor(
        {
            searchInputSelector,
            dataUserTypeSelector,
        }
    ) {
        this.inputSelector = searchInputSelector;
        this.userTypeSelector = dataUserTypeSelector;
    }


    init() {
        this.setUserType();
        this.initSearchBar();
    }

    async getProducts(query) {
        const response = await fetch(`/api/v2/storefront/products?search=${query}&per_page=8&page=1&fields[product]=id,name,slug,vendorName&include`)
        const products = await response.json();

        return products.data.map((product) => {
            if (product.attributes) {
                const vendorName = product.attributes.vendorName ? `(${product.attributes.vendorName})` : '';
                return {
                    'name': `${product.attributes.name} <span class="search-bar__vendor">${vendorName}</span>`,
                    'productAttributes': product
                }
            }
        });
    }

    generateLinkToProduct(product) {
        if (this.userType === 'corporate') {
            return '/corporate/products/' + product.id
        } else {
            return '/products/' + product.attributes.slug
        }
    }

    setUserType() {
        const userTypeContainer = document.querySelector(this.userTypeSelector);
        this.userType = userTypeContainer.dataset.user
    }

    initSearchBar() {
        const autoCompleteJS = new autoComplete({
            selector: this.inputSelector,
            threshold: 2,
            wrapper: false,
            submit: true,
            data: {
                src: this.getProducts,
                keys: ['name']
            },
            resultsList: {
                tag: 'div',
                class: 'search-bar__suggestions',
                noResults: false,
                maxResults: 8,
                element: (list, data) => {
                    const hrElement = document.createElement('hr');
                    hrElement.setAttribute('style', 'width: 100%; margin-top: 2px;margin-bottom: 2px;');
                    const parentForm = list.closest('form');

                    const showAllElement = document.createElement('button');
                    showAllElement.innerText = Spree.translations.show_all + ' ' + document.querySelector(this.inputSelector).value;


                    showAllElement.setAttribute('type', 'submit');
                    showAllElement.setAttribute('style', 'text-align: left;border: none;');

                    list.appendChild(hrElement);
                    list.appendChild(showAllElement);
                },
            },
            resultItem: {
                tag: 'a',
                class: 'search-bar__link',
                element: (item, data) => {
                    item.setAttribute("href", this.generateLinkToProduct(data.value.productAttributes));
                },
                highlight: true
            },
            debounce: 500,
            events: {
                input: {
                    focus() {
                        const inputValue = autoCompleteJS.input.value;

                        if (inputValue.length) {
                            autoCompleteJS.start();
                        }
                    },
                }
            }
        });
    }
}